import { db } from '@/firebaseConfig';
import { collection, query, limit, where, orderBy, getDocs, Timestamp } from 'firebase/firestore';
import { getStorage, ref, listAll, getDownloadURL } from 'firebase/storage';

export default {
	state: {
		sociales: [],
		sedes: [],
		membresias: [],
		eventos: [],
		noticias: [],
		medios: [],
		galeria: [],
		carnet: []
	},

	getters: {
		getSociales: state => state.sociales,
		getSedes: state => {
			return state.sedes.filter(sede => sede.estaActiva);
		},
		getSedesTramites: state => {
			return state.sedes.filter(sede => sede.estaActiva).filter(sede => sede.aceptaTramites);
		},
		getSedeConvocatoria: (state) => (id) => {
			return state.sedes.filter(sede => sede.logo == id)[0];
		},
		getMembresias: state => state.membresias,
		getEventos: state => {
			return state.eventos.sort((a, b) => a.fecha - b.fecha);
		},
		getProximoEvento: state => {
			let hoy = new Date();
			return state.eventos.find(e => (new Date(e.fecha.toDate()) >= hoy));
		},
		getNoticias: state => {
			return state.noticias.sort((a, b) => b.isPinned - a.isPinned).slice(0, 5);
		},
		getMedios: state => state.medios,
		getGaleria: state => state.galeria.sort().reverse(),
		getCarnet: state => state.carnet,
	},

	mutations: {
		SET_SOCIALES(state, payload) {
			state.sociales = payload;
		},
		SET_SEDES(state, payload) {
			state.sedes = payload;
		},
		SET_MEMBRESIAS(state, payload) {
			state.membresias = payload;
		},
		SET_EVENTOS(state, payload) {
			state.eventos = payload;
		},
		SET_NOTICIAS(state, payload) {
			state.noticias = payload;
		},
		SET_MEDIOS(state, payload) {
			state.medios = payload;
		},
		SET_GALERIA(state, payload) {
			state.galeria = payload;
		},
		SET_CARNET(state, payload) {
			state.carnet = payload;
		},
	},

	actions: {
		setSociales({ commit }, payload) {
			commit('SET_SOCIALES', payload);
		},
		setSedes({ commit }, payload) {
			commit('SET_SEDES', payload);
		},
		setMembresias({ commit }, payload) {
			commit('SET_MEMBRESIAS', payload);
		},
		async setEventos(context) {
			let hoy = new Date();
			const fechaHoy = Timestamp.fromDate(hoy);
			let payload = [];
			let q = query(collection(db, 'eventos'), where('fecha', '>=', fechaHoy));
			let querySnapshot = await getDocs(q);
			querySnapshot.forEach((doc) => {
				let post = doc.data();
				payload.push(post);
			});
			context.commit('SET_EVENTOS', payload);
		},
		async setNoticias(context) {
			let docs = [];

			let q1 = query(collection(db, 'noticias'), where('isPinned', '==', true));
			let querySnapshot1 = await getDocs(q1);
			querySnapshot1.forEach((doc) => {
				let post = Object.assign({ uid: doc.id }, doc.data());
				docs.push(post);
			});

			let q2 = query(collection(db, 'noticias'), orderBy('fecha', 'desc'), limit(5));
			let querySnapshot2 = await getDocs(q2);
			querySnapshot2.forEach((doc) => {
				let post = Object.assign({ uid: doc.id }, doc.data());
				docs.push(post);
			});

			let payload = [...new Map(docs.map((item) => [item['uid'], item])).values()];
			context.commit('SET_NOTICIAS', payload);
		},
		setMedios({ commit }, payload) {
			commit('SET_MEDIOS', payload);
		},
		async setGaleria(context) {
			const storage = getStorage();

			const listRef = ref(storage, 'galeria');
			let payload = [];

			listAll(listRef)
				.then((res) => {
					res.items.forEach((itemRef) => {
						getDownloadURL(itemRef)
							.then((url) => {
								payload.push(url);
							})
							.catch((error) => {
								// A full list of error codes is available at
								// https://firebase.google.com/docs/storage/web/handle-errors
							});
					});
				}).catch((error) => {
					// Uh-oh, an error occurred!
				});
			context.commit('SET_GALERIA', payload);
		},
		async setCarnet(context, queryData) {
			let payload = [];
			const q = query(collection(db, 'carnets'), where('sello', '==', queryData));

			const querySnapshot = await getDocs(q);
			querySnapshot.forEach((doc) => {
				payload.push(doc.data());
			});

			context.commit('SET_CARNET', payload[0]);
		}
	}
};
