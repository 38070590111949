<template>
	<v-card
		flat
		outlined
		class="mb-5"
		color="grey lighten-2"
		tag="article"
	>
		<v-img
			v-show="noticia.imagen"
			contain
			:src="imgURL"
		></v-img>
		<v-card-title class="text-break">{{ noticia.titulo }}</v-card-title>
		<v-card-subtitle>
			{{ formatDate(noticia.fecha) }}
		</v-card-subtitle>
		<v-card-text
			class="pt-2 grey--text text--darken-3 white"
			v-html="compiledMarkdown"
		>
		</v-card-text>
	</v-card>
</template>

<script>
import { marked } from 'marked';
import DOMPurify from 'dompurify';
import { storage } from '@/firebaseConfig';
import { ref, getDownloadURL } from 'firebase/storage';

export default {
	name: 'NoticiaArticulo',
	props: ['noticia'],

	data: () => ({
		imgURL: ''
	}),

	computed: {
		compiledMarkdown() {
			return DOMPurify.sanitize(marked(this.noticia.texto, { breaks: true }));
		}
	},

	methods: {
		formatDate(fecha) {
			const d = new Date(fecha.toDate());
			return `${(d.getDate()).toString().padStart(2, '0')}.${(d.getMonth() + 1).toString().padStart(2, '0')}.${(d.getFullYear()).toString().substr(-2)}`;
		},
	},

	created() {
		if (this.noticia.imagen) {
			const starsRef = ref(storage, 'noticias/' + this.noticia.imagen);

			// Get the download URL
			getDownloadURL(starsRef)
				.then((url) => {
					// Insert url into an <img> tag to "download"
					this.imgURL = url;
				})
				.catch((error) => {
					// A full list of error codes is available at
					// https://firebase.google.com/docs/storage/web/handle-errors
					this.imgURL = '';
				});
		}
	}
};
</script>

<style lang="scss" scoped>
	em {
		color: $secondary-darken1;
		font-size: 0.875rem;
		font-weight: 400;
	}
</style>
