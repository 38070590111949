<template>
	<v-card
		flat
		outlined
		class="mb-5"
		color="grey lighten-2"
		tag="article"
	>
		<v-card-title class="px-3">
			<v-img
				:src="require('@/assets/img/ligas/140.png')"
				max-width="30"
				contain
				class="mr-5"
			></v-img>Tabla de Posiciones
		</v-card-title>
		<v-card-text class="px-0 white">
			<v-simple-table
				dense
				class="posiciones mb-4"
			>
				<thead>
					<tr>
						<th class="pa-0 text-center">Pos</th>
						<th class="pa-0 text-left"></th>
						<th class="pa-0 text-left"></th>
						<th class="pa-0 text-center">Pts</th>
						<th class="pa-0 text-center">J</th>
						<th class="pa-0 text-center">G</th>
						<th class="pa-0 text-center">P</th>
						<th class="pa-0 text-center">E</th>
						<th class="pa-0 text-center">Dif</th>
					</tr>
				</thead>
				<tbody>
					<tr
						v-for="(e, i) in posiciones"
						:key="e.rank"
						:class="{ altCelda: i % 2 == 0 }"
					>
						<td
							class="pa-0 text-center pos text-caption"
							:class="{ ucl: i < 4, uel: i == 4 || i == 5, ufl: i == 6, des: i > 16 }"
						>
							{{ e.rank }}
						</td>
						<td class="pa-0 text-center">
							<v-img
								:src="'./img/equipos/' + e.team.id + '.png'"
								max-width="20"
								contain
								class="ml-2"
							></v-img>
						</td>
						<td
							class="pa-1 text-left"
							:class="{ 'font-weight-bold primary--text': e.team.id == 541 }"
						>
							{{ e.team.name }}
						</td>
						<td
							class="pa-1 text-center font-weight-medium"
							:class="{ 'font-weight-bold primary--text': e.team.id == 541 }"
						>
							{{ e.points }}
						</td>
						<td
							class="pa-1 text-center"
							:class="{ 'font-weight-bold primary--text': e.team.id == 541 }"
						>
							{{ e.all.played }}
						</td>
						<td
							class="pa-1 text-center"
							:class="{ 'font-weight-bold primary--text': e.team.id == 541 }"
						>
							{{ e.all.win }}
						</td>
						<td
							class="pa-1 text-center"
							:class="{ 'font-weight-bold primary--text': e.team.id == 541 }"
						>
							{{ e.all.lose }}
						</td>
						<td
							class="pa-1 text-center"
							:class="{ 'font-weight-bold primary--text': e.team.id == 541 }"
						>
							{{ e.all.draw }}
						</td>
						<td
							class="pa-1 text-center"
							:class="{ 'font-weight-bold primary--text': e.team.id == 541 }"
						>
							{{ e.goalsDiff }}
						</td>
					</tr>
				</tbody>
			</v-simple-table>
			<v-row class="text-caption flex-wrap px-4">
				<v-col
					cols="6"
					class="pa-0"
				>
					<div class="legend ucl"></div>Champions League
				</v-col>
				<v-col
					cols="6"
					class="pa-0"
				>
					<div class="legend uel"></div>Europa League
				</v-col>
				<v-col
					cols="6"
					class="pa-0"
				>
					<div class="legend ufl"></div>Conference League
				</v-col>
				<v-col
					cols="6"
					class="pa-0"
				>
					<div class="legend des"></div>Descenso
				</v-col>
			</v-row>
		</v-card-text>
	</v-card>
</template>

<script>
export default {
	name: 'TablaPosiciones',
	props: ['posiciones'],

};
</script>

<style lang="scss" scoped>
	.altCelda {
		background-color: #fafafa;
	}
	.pos {
		background-color: #f5f5f5;
	}
	.altCelda .pos {
		background-color: #e6e6e6;
	}
	.ucl {
		background-color: #c8e6c9;
	}
	.altCelda .ucl {
		background-color: #b9dfba;
	}
	.uel {
		background-color: #ffe0b2;
	}
	.altCelda .uel {
		background-color: #ffd699;
	}
	.ufl,
	.altCelda .ufl {
		background-color: #e1bee7;
	}
	.des {
		background-color: #ffcdd2;
	}
	.altCelda .des {
		background-color: #ffb8bf;
	}
	.posiciones td {
		vertical-align: middle;
	}
	.posiciones tr:last-child td {
		border-bottom-width: thin;
		border-bottom-style: solid;
		border-bottom-color: rgba(0, 0, 0, 0.12);
	}
	.legend {
		display: inline-block;
		width: 16px;
		height: 12px;
		margin: 0 8px;
	}
</style>
