import axios from 'axios';
import { db } from '@/firebaseConfig';
import { collection, query, limit, orderBy, getDocs } from 'firebase/firestore';

export default {
	state: {
		temporadaActual: 2023,
		ligas: [],
		equipos: [],
		calendario: [],
		posiciones: [],
		plantilla: [],
		analisisPrev: [],
		analisisPost: [],
	},

	getters: {
		getTemporadaActual: state => state.temporadaActual,
		getCalendario: state => state.calendario,
		getLigas: state => state.ligas,
		getEquipos: state => state.equipos,
		getLiga: state => id => {
			return state.ligas.find(l => l.id == id);
		},
		getEquipo: state => id => {
			return state.equipos.find(e => e.id == id);
		},
		getPartido: state => id => {
			return state.calendario.find(p => p.partidoId == id);
		},
		getPosiciones: state => state.posiciones,
		getPlantilla: state => state.plantilla,
		getJugador: state => numero => {
			return state.plantilla.find(j => j.numero == numero);
		},
		getAnalisisPrev: state => state.analisisPrev,
		getAnalisisPost: state => state.analisisPost,
	},

	mutations: {
		SET_LIGAS(state, payload) {
			if (state.ligas.length == 0) state.ligas = payload;
		},
		SET_EQUIPOS(state, payload) {
			if (state.equipos.length == 0) state.equipos = payload;
		},
		SET_CALENDARIO(state, payload) {
			if (state.calendario.length == 0) state.calendario = payload;
		},
		SET_POSICIONES(state, payload) {
			if (state.posiciones.length == 0) state.posiciones = payload;
		},
		SET_PLANTILLA(state, payload) {
			if (state.plantilla.length == 0) state.plantilla = payload;
		},
		SET_ANALISISPREV(state, payload) {
			if (state.analisisPrev.length == 0) state.analisisPrev.push(payload);
		},
		SET_ANALISISPOST(state, payload) {
			if (state.analisisPost.length == 0) state.analisisPost.push(payload);
		},
	},

	actions: {
		setLigas({ commit }, payload) {
			commit('SET_LIGAS', payload);
		},
		setEquipos({ commit }, payload) {
			commit('SET_EQUIPOS', payload);
		},
		async setCalendario(context) {
			const q = query(collection(db, 'calendarioPartidos'), orderBy('fecha', 'asc'));

			const querySnapshot = await getDocs(q);
			let payload = [];
			querySnapshot.forEach((doc) => {
				let post = doc.data();
				payload.push(post);
			});
			context.commit('SET_CALENDARIO', payload);
		},
		setPosiciones(context) {
			if (process.env.VUE_APP_DEV == 1) {
				axios
					.get('./standings.json')
					.then(response => response.data)
					.then(items => context.commit('SET_POSICIONES', items[0].league.standings[0]));
			} else {
				axios
					.get('https://v3.football.api-sports.io/standings?league=140&season=2024', {
						headers: {
							'x-rapidapi-host': 'v3.football.api-sports.io',
							'x-rapidapi-key': process.env.VUE_APP_RAPID_API
						}
					})
					.then(response => {
						context.commit('SET_POSICIONES', response.data.response[0].league.standings[0]);
					});
			}
		},
		setPlantilla({ commit }, payload) {
			commit('SET_PLANTILLA', payload);
		},
		async setAnalisisPrev(context) {
			const q = query(collection(db, 'analisisPrevios'), orderBy('partidoId', 'desc'), limit(1));

			const querySnapshot = await getDocs(q);
			let payload = {};
			querySnapshot.forEach((doc) => {
				let post = doc.data();
				payload = post;
			});
			context.commit('SET_ANALISISPREV', payload);
		},
		async setAnalisisPost(context) {
			const q = query(collection(db, 'analisisResumen'), orderBy('partidoId', 'desc'), limit(1));

			const querySnapshot = await getDocs(q);
			let payload = {};
			querySnapshot.forEach((doc) => {
				let post = doc.data();
				payload = post;
			});
			context.commit('SET_ANALISISPOST', payload);
		},
	}
};
