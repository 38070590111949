export const datosMedios = [
	{
		id: '-p_hYkmKHRo',
		alt: 'Programa Madridistas - @radiointereconomía (España)',
	},
	{
		id: 'DxvAIdAt0as',
		alt: 'Saludo de Tomás Roncero - Diario AS (España) - Temporada 2019/20',
	},
	{
		id: 'pAJruroB6Xw',
		alt: 'Programa Madridistas - @radiointereconomía (España)',
	},
	{
		id: '41CSQ7WcDOc',
		alt: 'El Chiringuito de Jugones - Antena 3 (España)',
	},
	{
		id: '-f3i4dwHtns',
		alt: 'Saludo de Tomás Roncero - Diario AS (España)',
	},
	{
		id: '0s0BPA2nZno',
		alt: 'Entrega de Bufanda de la Peña a Tomás Roncero - Diario AS (España)',
	},
	{
		id: 'vIMoPyLnvLs',
		alt: 'El Clásico - TV Azteca',
	},
	{
		id: 'A6BXuSSSJSc',
		alt: 'El Clásico - AS.com',
	}
];
