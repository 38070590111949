import Vue from 'vue';
import Vuex from 'vuex';
import Pena from './modules/pena';
import Futbol from './modules/futbol';

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
	},
	getters: {
	},
	mutations: {
	},
	actions: {
	},
	modules: {
		Pena,
		Futbol
	}
});
