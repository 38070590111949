<template>
	<div>
		<v-avatar>
			<v-img :src="require('@/assets/img/plantilla/' + jugador.numero + '.png')"></v-img>
		</v-avatar>
		<p class="mb-0"><span class="primary--text font-weight-bold">{{ jugador.numero }}</span> {{ jugador.nombreCorto }}</p>
	</div>
</template>

<script>
export default {
	name: 'AvatarJugador',
	props: ['jugador'],
};
</script>
