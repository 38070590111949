export const datosLigas = [
	{
		id: 2,
		nombre: 'UEFA Champions League'
	},
	{
		id: 15,
		nombre: 'FIFA Mundial de Clubes'
	},
	{
		id: 140,
		nombre: 'Primera División'
	},
	{
		id: 143,
		nombre: 'Copa Del Rey'
	},
	{
		id: 531,
		nombre: 'Supercopa de Europa'
	},
	{
		id: 556,
		nombre: 'Supercopa de España'
	},
	{
		id: 667,
		nombre: 'Partidos Amistosos'
	}
];

export const datosEquipos = [
	{
		id: 33,
		nombre: 'Manchester United',
		pais: 'ENG',
		codigo: 'MUN'
	},
	{
		id: 40,
		nombre: 'Liverpool',
		pais: 'ENG',
		codigo: 'LIV'
	},
	{
		id: 49,
		nombre: 'Chelsea',
		pais: 'ENG',
		codigo: 'CHE'
	},
	{
		id: 50,
		nombre: 'Manchester City',
		pais: 'ENG',
		codigo: 'MCI'
	},
	{
		id: 81,
		nombre: 'Olimpique Marseille',
		pais: 'FRA',
		codigo: 'OLM'
	},
	{
		id: 85,
		nombre: 'Paris Saint Germain',
		pais: 'FRA',
		codigo: 'PSG'
	},
	{
		id: 94,
		nombre: 'Rennes',
		pais: 'FRA',
		codigo: 'REN'
	},
	{
		id: 157,
		nombre: 'Bayern Munich',
		pais: 'GER',
		codigo: 'BAY'
	},
	{
		id: 163,
		nombre: 'Borussia Mönchengladbach',
		pais: 'GER',
		codigo: 'BAY'
	},
	{
		id: 165,
		nombre: 'Borussia Dortmund',
		pais: 'GER',
		codigo: 'DOR'
	},
	{
		id: 169,
		nombre: 'Eintracht Frankfurt',
		pais: 'GER',
		codigo: 'EIN'
	},
	{
		id: 173,
		nombre: 'RB Leipzig',
		pais: 'GER',
		codigo: 'RBL'
	},
	{
		id: 182,
		nombre: 'Union Berlin',
		pais: 'GER',
		codigo: 'UBE'
	},
	{
		id: 194,
		nombre: 'Ajax',
		pais: 'NED',
		codigo: 'AJA'
	},
	{
		id: 212,
		nombre: 'FC Porto',
		pais: 'POR',
		codigo: 'POR'
	},
	{
		id: 217,
		nombre: 'Braga',
		pais: 'POR',
		codigo: 'BRA'
	},
	{
		id: 247,
		nombre: 'Celtic',
		pais: 'SCO',
		codigo: 'CEL'
	},
	{
		id: 257,
		nombre: 'Rangers F.C.',
		pais: 'SCO',
		codigo: 'RAN'
	},
	{
		id: 397,
		nombre: 'FC Midtjylland',
		pais: 'DEN',
		codigo: 'FCM'
	},
	{
		id: 487,
		nombre: 'Lazio',
		pais: 'ITA',
		codigo: 'LAZ'
	},
	{
		id: 489,
		nombre: 'AC Milan',
		pais: 'ITA',
		codigo: 'MIL'
	},
	{
		id: 492,
		nombre: 'Napoli',
		pais: 'ITA',
		codigo: 'NAP'
	},
	{
		id: 496,
		nombre: 'Juventus',
		pais: 'ITA',
		codigo: 'JUV'
	},
	{
		id: 499,
		nombre: 'Atalanta',
		pais: 'ITA',
		codigo: 'ATT'
	},
	{
		id: 505,
		nombre: 'Inter Milán',
		pais: 'ITA',
		codigo: 'INT'
	},
	{
		id: 529,
		nombre: 'Barcelona',
		pais: 'ESP',
		codigo: 'BAR'
	},
	{
		id: 530,
		nombre: 'Atlético Madrid',
		pais: 'ESP',
		codigo: 'ATM'
	},
	{
		id: 531,
		nombre: 'Athletic Club',
		pais: 'ESP',
		codigo: 'ATH'
	},
	{
		id: 532,
		nombre: 'Valencia',
		pais: 'ESP',
		codigo: 'VAL'
	},
	{
		id: 533,
		nombre: 'Villarreal',
		pais: 'ESP',
		codigo: 'VIL'
	},
	{
		id: 534,
		nombre: 'Las Palmas',
		pais: 'ESP',
		codigo: 'PAL'
	},
	{
		id: 536,
		nombre: 'Sevilla',
		pais: 'ESP',
		codigo: 'SEV'
	},
	{
		id: 538,
		nombre: 'Celta de Vigo',
		pais: 'ESP',
		codigo: 'CEL'
	},
	{
		id: 539,
		nombre: 'Levante',
		pais: 'ESP',
		codigo: 'LEV'
	},
	{
		id: 540,
		nombre: 'Espanyol',
		pais: 'ESP',
		codigo: 'ESP'
	},
	{
		id: 541,
		nombre: 'Real Madrid',
		pais: 'ESP',
		codigo: 'RMA'
	},
	{
		id: 542,
		nombre: 'Alavés',
		pais: 'ESP',
		codigo: 'ALA'
	},
	{
		id: 543,
		nombre: 'Real Betis',
		pais: 'ESP',
		codigo: 'BET'
	},
	{
		id: 545,
		nombre: 'Eibar',
		pais: 'ESP',
		codigo: 'EIB'
	},
	{
		id: 546,
		nombre: 'Getafe',
		pais: 'ESP',
		codigo: 'GET'
	},
	{
		id: 547,
		nombre: 'Girona',
		pais: 'ESP',
		codigo: 'GIR'
	},
	{
		id: 548,
		nombre: 'Real Sociedad',
		pais: 'ESP',
		codigo: 'RSO'
	},
	{
		id: 550,
		nombre: 'Shakhtar Donetsk',
		pais: 'UKR',
		codigo: 'SHK'
	},
	{
		id: 553,
		nombre: 'Olympiakos Piraeus',
		pais: 'GRE',
		codigo: 'OLY'
	},
	{
		id: 564,
		nombre: 'Istanbul Basaksehir',
		pais: 'TUR',
		codigo: 'IBA'
	},
	{
		id: 568,
		nombre: 'Sheriff Tiraspol',
		pais: 'MDA',
		codigo: 'SHF'
	},
	{
		id: 569,
		nombre: 'Club Brugge KV',
		pais: 'BEL',
		codigo: 'BRU'
	},
	{
		id: 571,
		nombre: 'Red Bull Salzburg',
		pais: 'AUT',
		codigo: 'RBS'
	},
	{
		id: 572,
		nombre: 'Dynamo Kyiv',
		pais: 'UKR',
		codigo: 'DYK'
	},
	{
		id: 596,
		nombre: 'Zenit Saint Petersburg',
		pais: 'RUS',
		codigo: 'ZSP'
	},
	{
		id: 597,
		nombre: 'Lokomotiv Moscow',
		pais: 'RUS',
		codigo: 'LMO'
	},
	{
		id: 621,
		nombre: 'Krasnodar',
		pais: 'RUS',
		codigo: 'KRA'
	},
	{
		id: 651,
		nombre: 'Ferencvarosi TC',
		pais: 'HUN',
		codigo: 'FER'
	},
	{
		id: 715,
		nombre: 'Granada CF',
		pais: 'ESP',
		codigo: 'GRA'
	},
	{
		id: 720,
		nombre: 'Real Valladolid',
		pais: 'ESP',
		codigo: 'VLL'
	},
	{
		id: 723,
		nombre: 'Almería',
		pais: 'ESP',
		codigo: 'ALM'
	},
	{
		id: 724,
		nombre: 'Cádiz',
		pais: 'ESP',
		codigo: 'CAD'
	},
	{
		id: 726,
		nombre: 'Huesca',
		pais: 'ESP',
		codigo: 'HUE'
	},
	{
		id: 727,
		nombre: 'Osasuna',
		pais: 'ESP',
		codigo: 'OSA'
	},
	{
		id: 728,
		nombre: 'Rayo Vallecano',
		pais: 'ESP',
		codigo: 'RAY'
	},
	{
		id: 797,
		nombre: 'Elche',
		pais: 'ESP',
		codigo: 'ELC'
	},
	{
		id: 798,
		nombre: 'Mallorca',
		pais: 'ESP',
		codigo: 'MLL'
	},
	{
		id: 9384,
		nombre: 'Cacereño',
		pais: 'ESP',
		codigo: 'CAC'
	},
	{
		id: 9722,
		nombre: 'Arandina',
		pais: 'ESP',
		codigo: 'ARA'
	}
];
