export default {
	primary: {
		base: '#325da3',
		lighten5: '#dfe7f5',
		lighten4: '#c7d3e7',
		lighten3: '#9eb4d8',
		lighten2: '#7998cc',
		lighten1: '#5780c3',
		darken1: '#2d5088',
		darken2: '#284471',
		darken3: '#243a5e',
		darken4: '#20324f'
	},
	secondary: {
		base: '#dc2b43',
		lighten5: '#fbf3f4',
		lighten4: '#efc6cb',
		lighten3: '#e99aa4',
		lighten2: '#e76f7f',
		lighten1: '#eb445a',
		darken1: '#bc293d',
		darken2: '#9d2b3a',
		darken3: '#832b36',
		darken4: '#6e2932'
	},
	accent: {
		base: '#a7711c',
		lighten5: '#fcf8f2',
		lighten4: '#f2d8af',
		lighten3: '#efbe6f',
		lighten2: '#f4aa33',
		lighten1: '#d88e18',
		darken1: '#815b1d',
		darken2: '#64491c',
		darken3: '#4e3a1a',
		darken4: '#3d2f17'
	},
	error: {
		base: '#dc5046',
		lighten5: '#fcf4f3',
		lighten4: '#f5cdca',
		lighten3: '#f2a7a1',
		lighten2: '#f38179',
		lighten1: '#e8665d',
		darken1: '#ce3e34',
		darken2: '#b33c33',
		darken3: '#9a3a33',
		darken4: '#853832'
	},
	success: {
		base: '#43a347',
		lighten5: '#f4fbf5',
		lighten4: '#c5e7c7',
		lighten3: '#9dd79f',
		lighten2: '#79c97c',
		lighten1: '#56ba5a',
		darken1: '#398a3c',
		darken2: '#317433',
		darken3: '#29622c',
		darken4: '#235325'
	},
	warning: {
		base: '#ffc107',
		lighten5: '#fff8e1',
		lighten4: '#ffecb3',
		lighten3: '#ffe082',
		lighten2: '#ffd54f',
		lighten1: '#ffca28',
		darken1: '#ffb300',
		darken2: '#ffa000',
		darken3: '#ff8f00',
		darken4: '#ff6f00'
	},
	info: {
		base: '#586acf',
		lighten5: '#f4f5fb',
		lighten4: '#d1d5ed',
		lighten3: '#b0b7e3',
		lighten2: '#919cda',
		lighten1: '#7482d4',
		darken1: '#4256c4',
		darken2: '#3b4cb0',
		darken3: '#37469a',
		darken4: '#334087'
	}
};
