<template>
	<v-card
		flat
		outlined
		class="mb-5"
		color="grey lighten-2"
		tag="section"
	>
		<v-card-title
			v-if="hasTituloSlot"
			class="grey--text text--darken-4"
		>
			<slot name="titulo"></slot>
		</v-card-title>
		<v-card-text class="grey--text text--darken-3 white px-0 py-3">
			<v-row
				no-gutters
				dense
			>
				<v-col
					cols="4"
					align-self="end"
					class="text-center font-weight-medium"
				>
					<v-img
						:src="require('@/assets/img/equipos/' + local.id + '.png')"
						width="80"
						max-height="80"
						class="d-block mb-2 mx-auto"
					></v-img>
					{{ local.nombre }}
				</v-col>
				<v-col
					cols="4"
					align-self="start"
					class="text-center"
				>
					<div class="text-h6">{{ formatDate(partido.fecha) }}</div>
					<div class="text-subtitle-2 mb-2">
						{{ partido.horario === 'TBD' ? 'Horario Por Definir' : formatTime(partido.fecha) }}
					</div>
					<img
						:src="require('@/assets/img/ligas/' + liga.id + '.png')"
						width="40"
						contain
						class="mx-auto"
						:alt="liga.nombre"
						:title="liga.nombre"
					>
					<div class="text-caption">{{ partido.jornada }}</div>
				</v-col>
				<v-col
					cols="4"
					align-self="end"
					class="text-center font-weight-medium"
				>
					<v-img
						:src="require('@/assets/img/equipos/' + visita.id + '.png')"
						width="80"
						max-height="80"
						class="d-block mb-2 mx-auto"
					></v-img>
					{{ visita.nombre }}
				</v-col>
				<v-col
					cols="12"
					class="text-center text-caption py-0"
				>
					{{ partido.estadio }}
				</v-col>
			</v-row>
		</v-card-text>
		<v-divider class="grey lighten-2"></v-divider>
		<v-card-actions class="white text-body-2 grey--text text--darken-3 flex-column align-start">
			<template v-if="partido.sedeId != null">
				<p class="mb-1">Convocatoria:</p>
				<template v-if="partido.sedeId != 'no'">
					<v-img
						contain
						max-width="150"
						:src="require('@/assets/img/' + sedeConvocatoria.logo + '.png')"
						:alt="sedeConvocatoria.nombre"
						class="mx-auto mb-2"
					/>
				</template>
				<p class="mb-1">
					<template v-if="partido.sedeId != 'no'">
						<v-icon
							color="primary lighten-3"
							left
						>mdi-map-marker</v-icon> <a
							:href="sedeConvocatoria.urlMapa"
							:title="sedeConvocatoria.nombre"
							target="_blank"
							rel="noopener noreferrer"
							class="text-decoration-none"
						>{{ sedeConvocatoria.nombre }}</a>
					</template>
					<template v-else>
						Acude por tu cuenta a cualquiera de nuestras sedes y aprovecha tu descuento.
					</template>
				</p>
			</template>
			<p
				class="mb-1"
				v-if="partido.nota"
			>
				<v-icon
					color="primary lighten-3"
					left
				>mdi-television</v-icon> {{ partido.nota }}
			</p>
		</v-card-actions>
	</v-card>
</template>

<script>
export default {
	name: 'VerPartido',
	props: ['partido'],

	computed: {
		liga () {
			return this.$store.getters.getLiga(this.partido.liga);
		},
		local () {
			return this.$store.getters.getEquipo(this.partido.local);
		},
		visita () {
			return this.$store.getters.getEquipo(this.partido.visita);
		},
		hasTituloSlot () {
			return !!this.$slots.titulo;
		},
		sedeConvocatoria () {
			return this.$store.getters.getSedeConvocatoria(this.partido.sedeId);
		}
	},

	methods: {
		formatDate (fecha) {
			const d = new Date(fecha.toDate());
			const hoy = new Date();
			if (hoy.getDate() == d.getDate() && hoy.getMonth() == d.getMonth()) {
				return 'Hoy';
			} else {
				return `${ (d.getDate()).toString().padStart(2, '0') }.${ (d.getMonth() + 1).toString().padStart(2, '0') }.${ (d.getFullYear()).toString().substr(-2) }`;
			}
		},
		formatTime (fecha) {
			const d = new Date(fecha.toDate());
			return `${ d.getHours() }:${ (d.getMinutes()).toString()
				.padStart(2, '0') }`;
		}
	},
};
</script>
