import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import es from 'vuetify/lib/locale/es';
import light from './vuetify/theme';

Vue.use(Vuetify);

export default new Vuetify({
	lang: {
		locales: { es },
		current: 'es',
	},
	theme: {
		themes: {
			light
		},
	},
});
