<template>
	<v-card
		flat
		outlined
		class="mb-10"
		color="grey lighten-2"
		tag="article"
	>
		<v-card-title class="justify-space-between">
			<div>
				{{ partido.jornada }}<br />
				<span class="text-subtitle-2">Análisis Previo</span>
			</div>
			<img
				:src="require('@/assets/img/ligas/' + liga.id + '.png')"
				width="40"
				contain
			>
		</v-card-title>
		<v-card-text class="px-0 pt-3 grey--text text--darken-3 white">
			<v-row
				no-gutters
				dense
			>
				<v-col
					cols="6"
					align-self="start"
					class="text-center font-weight-medium"
				>
					<v-img
						:src="require('@/assets/img/equipos/' + local.id + '.png')"
						width="50"
						class="d-block mb-2 mx-auto"
					></v-img>
					<div>{{ local.nombre }}</div>
					<template v-if="analisis.localPos != null">
						<span class="text-body-2 grey--text text--darken-1">{{ analisis.localPos }}º ({{ analisis.localPts }} pts.)</span>
					</template>
				</v-col>
				<v-col
					cols="6"
					align-self="start"
					class="text-center font-weight-medium"
				>
					<v-img
						:src="require('@/assets/img/equipos/' + visita.id + '.png')"
						width="50"
						class="d-block mb-2 mx-auto"
					></v-img>
					<div>{{ visita.nombre }}</div>
					<template v-if="analisis.visitaPos != null">
						<span class="text-body-2 grey--text text--darken-1">{{ analisis.visitaPos }}º ({{ analisis.visitaPts }} pts.)</span>
					</template>
				</v-col>
			</v-row>
			<div class="pa-2 text-body-1">{{ analisis.texto }}</div>
			<div class="grey lighten-2 text-uppercase pa-2 font-weight-medium">Real Madrid:</div>
			<div class="pa-2 primary--text font-weight-medium">Posible Once Titular:</div>
			<v-row
				dense
				no-gutters
				class="py-2"
			>
				<v-col
					v-for="j in analisis.delantera"
					:key="j"
				>
					<avatar-jugador
						:jugador="getJugador(j)"
						class="text-center"
					></avatar-jugador>
				</v-col>
			</v-row>
			<v-row
				dense
				no-gutters
				class="py-2"
			>
				<v-col
					v-for="j in analisis.media"
					:key="j"
				>
					<avatar-jugador
						:jugador="getJugador(j)"
						class="text-center"
					></avatar-jugador>
				</v-col>
			</v-row>
			<v-row
				dense
				no-gutters
				class="py-2"
			>
				<v-col
					v-for="j in analisis.defensa"
					:key="j"
				>
					<avatar-jugador
						:jugador="getJugador(j)"
						class="text-center"
					></avatar-jugador>
				</v-col>
			</v-row>
			<v-row
				dense
				no-gutters
				class="py-2"
			>
				<v-col
					v-for="j in analisis.porteria"
					:key="j"
				>
					<avatar-jugador
						:jugador="getJugador(j)"
						class="text-center"
					></avatar-jugador>
				</v-col>
			</v-row>
			<div class="px-2 secondary--text font-weight-medium">Lesionados o sancionados:</div>
			<div class="py-2 px-4">
				<template v-if="analisis.lesionados.length > 0">
					<template v-for="(j, index) in analisis.lesionados">
						{{ getJugador(j).nombreCorto }}<template v-if="index < analisis.lesionados.length - 1">, </template>
					</template>
				</template>
				<template v-else>
					No hay jugadores lesionados o sancionados.
				</template>
			</div>
			<div class="grey lighten-2 text-uppercase mb-2 pa-2 font-weight-medium">El Rival:</div>
			<div class="pa-2">
				<span class="font-weight-medium">Entrenador:&nbsp;</span><span>{{ analisis.entrenador }}</span>
			</div>
			<div class="pa-2">
				<span class="font-weight-medium">Estrella:&nbsp;</span><span>{{ analisis.estrella }}</span>
			</div>
			<template v-if="analisis.anteriores.length > 0">
				<div class="grey lighten-2 text-uppercase my-2 pa-2 font-weight-medium">Enfrentamientos Anteriores:</div>
				<v-simple-table class="px-4">
					<tr
						v-for="(e, i) in analisis.anteriores"
						:key="i"
					>
						<td class="font-weight-medium">
							{{ e.temporada }}
						</td>
						<template v-if="!e.notas">
							<template v-if="!e.inverted">
								<td
									class="text-center"
									:class="{ 'primary--text': e.mLocal > e.mVisita, 'font-weight-bold': e.mLocal > e.mVisita }"
								>{{ local.codigo }}</td>
								<td class="mx-2 font-weight-bold text-center">{{ e.mLocal }} : {{ e.mVisita }}</td>
								<td
									class="text-center pr-2"
									:class="{ 'primary--text': e.mVisita > e.mLocal, 'font-weight-bold': e.mVisita > e.mLocal }"
								>{{ visita.codigo }}</td>
							</template>
							<template v-else>
								<td
									class="text-center"
									:class="{ 'primary--text': e.mLocal > e.mVisita, 'font-weight-bold': e.mLocal > e.mVisita }"
								>{{ visita.codigo }}</td>
								<td class="px-2 font-weight-bold text-center">{{ e.mLocal }} : {{ e.mVisita }}</td>
								<td
									class="text-center pr-2"
									:class="{ 'primary--text': e.mVisita > e.mLocal, 'font-weight-bold': e.mVisita > e.mLocal }"
								>{{ local.codigo }}</td>
							</template>
							<td>
								{{ e.fase }}
							</td>
						</template>
						<template v-else>
							<td colspan="4">{{ e.notas }}</td>
						</template>
					</tr>
				</v-simple-table>
			</template>
			<template v-else>
				<div class="grey lighten-2 text-uppercase my-2 pa-2 font-weight-medium">Enfrentamientos Anteriores:</div>
				<p class="ml-4">Sin enfrentamientos históricos</p>
			</template>
		</v-card-text>
	</v-card>
</template>

<script>
import AvatarJugador from './AvatarJugador.vue';

export default {
	name: 'AnalisisPrev',
	props: ['analisis'],
	components: { AvatarJugador },

	data: () => ({

	}),

	computed: {
		calendario () {
			return this.$store.getters.getCalendario;
		},
		partido () {
			return this.$store.getters.getPartido(this.analisis.partidoId);
		},
		liga () {
			return this.$store.getters.getLiga(this.partido.liga);
		},
		local () {
			return this.$store.getters.getEquipo(this.partido.local);
		},
		visita () {
			return this.$store.getters.getEquipo(this.partido.visita);
		},
		totalEncuentros () {
			return this.analisis.infografia.jgLocal + this.analisis.infografia.je + this.analisis.infografia.jgVisita;
		}
	},

	methods: {
		getJugador (numero) {
			return this.$store.getters.getJugador(numero);
		},
		getPorcentaje (numero) {
			return (numero * 100) / this.totalEncuentros;
		}
	}
};
</script>

<style lang="scss" scoped>
	.grafica {
		height: 10px;
		width: 100%;
		margin-bottom: 1px;
	}
	.local {
		background-color: $secondary-lighten4;
	}
	.empate {
		background-color: $accent-lighten4;
	}
	.visita {
		background-color: $primary-lighten4;
	}
</style>
