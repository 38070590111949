export const datosPlantilla = [
	{
		numero: 1,
		nombre: 'Thibaut Courtois',
		nombreCorto: 'Courtois',
		posicion: 'POR',
		nacionalidad: 'BEL'
	},
	{
		numero: 2,
		nombre: 'Daniel Carvajal',
		nombreCorto: 'Carvajal',
		posicion: 'DEF',
		nacionalidad: 'ESP'
	},
	{
		numero: 3,
		nombre: 'Éder Militão',
		nombreCorto: 'Militão',
		posicion: 'DEF',
		nacionalidad: 'BRA'
	},
	{
		numero: 4,
		nombre: 'David Alaba',
		nombreCorto: 'Alaba',
		posicion: 'DEF',
		nacionalidad: 'AUT'
	},
	{
		numero: 5,
		nombre: 'Jude Bellingham',
		nombreCorto: 'Bellingham',
		posicion: 'MED',
		nacionalidad: 'GBR'
	},
	{
		numero: 6,
		nombre: 'José Ignacio Fernández',
		nombreCorto: 'Nacho',
		posicion: 'DEF',
		nacionalidad: 'ESP'
	},
	{
		numero: 7,
		nombre: 'Vinícius Júnior',
		nombreCorto: 'Vini Jr.',
		posicion: 'DEL',
		nacionalidad: 'BRA'
	},
	{
		numero: 8,
		nombre: 'Toni Kroos',
		nombreCorto: 'Kroos',
		posicion: 'MED',
		nacionalidad: 'DEU'
	},
	{
		numero: 10,
		nombre: 'Luka Modrić',
		nombreCorto: 'Modrić',
		posicion: 'MED',
		nacionalidad: 'HRV'
	},
	{
		numero: 11,
		nombre: 'Rodrygo Goes',
		nombreCorto: 'Rodrygo',
		posicion: 'DEL',
		nacionalidad: 'BRA'
	},
	{
		numero: 12,
		nombre: 'Eduardo Camavinga',
		nombreCorto: 'Camavinga',
		posicion: 'MED',
		nacionalidad: 'FRA'
	},
	{
		numero: 13,
		nombre: 'Andriy Lunin',
		nombreCorto: 'Lunin',
		posicion: 'POR',
		nacionalidad: 'UKR'
	},
	{
		numero: 14,
		nombre: 'Joselu Mato',
		nombreCorto: 'Joselu',
		posicion: 'DEL',
		nacionalidad: 'ESP'
	},
	{
		numero: 15,
		nombre: 'Federico Valverde',
		nombreCorto: 'Fede Valverde',
		posicion: 'MED',
		nacionalidad: 'URY'
	},
	{
		numero: 16,
		nombre: 'Álvaro Odriozola',
		nombreCorto: 'Odriozola',
		posicion: 'DEF',
		nacionalidad: 'ESP'
	},
	{
		numero: 17,
		nombre: 'Lucas Vázquez',
		nombreCorto: 'Lucas V.',
		posicion: 'MED',
		nacionalidad: 'ESP'
	},
	{
		numero: 18,
		nombre: 'Aurélien Tchouaméni',
		nombreCorto: 'Tchouaméni',
		posicion: 'MED',
		nacionalidad: 'FRA'
	},
	{
		numero: 19,
		nombre: 'Daniel Ceballos',
		nombreCorto: 'Dani Ceballos',
		posicion: 'MED',
		nacionalidad: 'ESP'
	},
	{
		numero: 20,
		nombre: 'Francisco García',
		nombreCorto: 'Fran García',
		posicion: 'DEF',
		nacionalidad: 'ESP'
	},
	{
		numero: 21,
		nombre: 'Brahim Díaz',
		nombreCorto: 'Brahim',
		posicion: 'DEL',
		nacionalidad: 'ESP'
	},
	{
		numero: 22,
		nombre: 'Antonio Rüdiger',
		nombreCorto: 'Rüdiger',
		posicion: 'DEF',
		nacionalidad: 'DEU'
	},
	{
		numero: 23,
		nombre: 'Ferland Mendy',
		nombreCorto: 'Mendy',
		posicion: 'DEF',
		nacionalidad: 'FRA'
	},
	{
		numero: 24,
		nombre: 'Arda Güler',
		nombreCorto: 'Arda Güler',
		posicion: 'MED',
		nacionalidad: 'TUR'
	},
	{
		numero: 25,
		nombre: 'Kepa Arrizabalaga',
		nombreCorto: 'Arrizabalaga',
		posicion: 'POR',
		nacionalidad: 'ESP'
	},
	{
		numero: 30,
		nombre: 'Reinier Jesus Carvalho',
		nombreCorto: 'Reinier',
		posicion: 'MED',
		nacionalidad: 'BRA'
	},
	{
		numero: 32,
		nombre: 'Nicolás Paz',
		nombreCorto: 'Nico Paz',
		posicion: 'MED',
		nacionalidad: 'ARG'
	},
	{
		numero: 33,
		nombre: 'Álvaro Carrillo',
		nombreCorto: 'Carrillo',
		posicion: 'DEF',
		nacionalidad: 'ESP'
	},
];
