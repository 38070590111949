<template>
	<v-card
		flat
		outlined
		class="mb-10"
		color="grey lighten-2"
		tag="article"
	>
		<v-card-title class="justify-space-between">
			<div>
				{{ partido.jornada }}<br />
				<span class="text-subtitle-2">Resumen</span>
			</div>
			<img
				:src="require('@/assets/img/ligas/' + liga.id + '.png')"
				width="40"
				contain
			>
		</v-card-title>
		<v-card-text class="px-0 pt-3 grey--text text--darken-3 white">
			<v-row
				no-gutters
				dense
			>
				<v-col
					cols="6"
					align-self="start"
					class="text-center font-weight-medium"
				>
					<v-img
						:src="require('@/assets/img/equipos/' + local.id + '.png')"
						width="50"
						class="d-block mb-2 mx-auto"
					></v-img>
					<div>{{ local.nombre }}</div>
					<span
						class="text-h5"
						:class="{ 'font-weight-black primary--text': analisis.localMarcador > analisis.visitaMarcador, 'secondary--text': analisis.localMarcador < analisis.visitaMarcador }"
					>{{ analisis.localMarcador }}</span>
				</v-col>
				<v-col
					cols="6"
					align-self="start"
					class="text-center font-weight-medium"
				>
					<v-img
						:src="require('@/assets/img/equipos/' + visita.id + '.png')"
						width="50"
						class="d-block mb-2 mx-auto"
					></v-img>
					<div>{{ visita.nombre }}</div>
					<span
						class="text-h5"
						:class="{ 'font-weight-black primary--text': analisis.visitaMarcador > analisis.localMarcador, 'secondary--text': analisis.visitaMarcador < analisis.localMarcador }"
					>{{ analisis.visitaMarcador }}</span>
				</v-col>
			</v-row>
			<div class="pa-2">{{ analisis.titular }}</div>
			<div class="grey lighten-2 text-uppercase pa-2 font-weight-medium">Los Goles:</div>
			<template v-if="analisis.goles.length > 0">
				<v-timeline>
					<v-timeline-item
						small
						v-for="(gol, i) in analisis.goles"
						:key="i"
						:left="gol.local"
						:right="!gol.local"
						:class="{ 'text-right': gol.local }"
					>
						<template v-slot:opposite>
							<span class="text-body-2">{{ gol.minuto }}'</span>
						</template>
						<template v-if="isNaN(gol.jugador)"><span class="primary--text font-weight-bold">{{ gol.jugador }}</span>
							<span
								v-if="gol.nota != ''"
								class="text--secondary"
							> ({{ gol.nota }})</span>
						</template>
						<template v-else><span class="primary--text font-weight-bold">{{ getJugador(gol.jugador).nombreCorto }}</span>
							<span
								v-if="gol.nota != ''"
								class="text--secondary"
							> ({{ gol.nota }})</span>
						</template>
					</v-timeline-item>
				</v-timeline>
			</template>
			<template v-else>
				<div class="pt-2 px-2">Sin goles</div>
			</template>
			<div class="grey lighten-2 text-uppercase my-2 pa-2 font-weight-medium">El Crack:</div>
			<div class="px-2">
				<span class="font-weight-medium primary--text">{{ analisis.crack }}: </span>{{ analisis.crackTexto }}
			</div>
			<div class="grey lighten-2 text-uppercase mt-2 pa-2 font-weight-medium">El Resumen:</div>
			<div class="pa-0">
				<a
					:href="'https://www.youtube.com/watch?v='+analisis.resumen"
					target="_blank"
				>
					<v-img :src="'http://img.youtube.com/vi/' + analisis.resumen + '/0.jpg'"></v-img>
				</a>
			</div>
			<div class="grey lighten-2 text-uppercase my-2 pa-2 font-weight-medium">La Estadística:</div>
			<v-simple-table class="px-4">
				<tr>
					<td>Posesión:</td>
					<td class="font-weight-medium">{{ analisis.estadistica.posesion }} %</td>
				</tr>
				<tr>
					<td>Tiros:</td>
					<td class="font-weight-medium">{{ analisis.estadistica.tiros }} ({{ analisis.estadistica.tirosAPuerta }} a puerta)</td>
				</tr>
				<tr>
					<td>Saques de Esquina:</td>
					<td class="font-weight-medium">{{ analisis.estadistica.saquesDeEsquina }}</td>
				</tr>
				<tr>
					<td>Fueras de Juego:</td>
					<td class="font-weight-medium">{{ analisis.estadistica.fuerasDeJuego }}</td>
				</tr>
				<tr>
					<td>Tarjetas:</td>
					<td class="font-weight-medium">
						<v-icon
							small
							color="amber"
						>mdi-cards</v-icon>{{ analisis.estadistica.tarjetasAmarillas
							}}<v-icon
							right
							small
							color="red"
						>mdi-cards</v-icon>{{ analisis.estadistica.tarjetasRojas }}
					</td>
				</tr>
			</v-simple-table>
			<div class="grey lighten-2 text-uppercase my-2 pa-2 font-weight-medium">La Polémica:</div>
			<template v-if="analisis.arbitroEventos.length > 0">
				<table class="px-4 arbitro">
					<tr
						v-for="(e, i) in analisis.arbitroEventos"
						:key="i"
					>
						<td class="font-weight-medium pr-2">
							{{ e.minuto }}'
						</td>
						<td>{{ e.evento }}</td>
					</tr>
				</table>
			</template>
			<template v-else>
				<div class="px-4">
					Sin jugadas polémicas
				</div>
			</template>
		</v-card-text>
	</v-card>
</template>

<script>

export default {
	name: 'AnalisisPost',
	props: ['analisis'],

	data: () => ({

	}),

	computed: {
		calendario() {
			return this.$store.getters.getCalendario;
		},
		partido() {
			return this.$store.getters.getPartido(this.analisis.partidoId);
		},
		liga() {
			return this.$store.getters.getLiga(this.partido.liga);
		},
		local() {
			return this.$store.getters.getEquipo(this.partido.local);
		},
		visita() {
			return this.$store.getters.getEquipo(this.partido.visita);
		},
	},

	methods: {
		getJugador(numero) {
			return this.$store.getters.getJugador(numero);
		}
	}
};
</script>

<style lang="scss" scoped>
	.arbitro > tr > td {
		vertical-align: top;
	}
</style>
