<template>
	<v-app>
		<v-app-bar
			app
			color="primary"
			dark
			:prominent="$vuetify.breakpoint.mdAndUp"
			class="text-h5"
		>
			<router-link
				to="/"
				class="ml-2 align-self-center"
			>
				<v-img
					src="./assets/img/logo.svg"
					contain
					:width="$vuetify.breakpoint.smAndDown ? 185 : 380"
					position="top"
					alt="Peña Madridista de la Ciudad de México"
				></v-img>
			</router-link>
			<v-spacer></v-spacer>
			<v-container
				fill-height
				class="d-flex flex-column justify-space-between pa-0 pr-lg-2"
			>
				<v-app-bar-nav-icon
					@click.stop="drawer = !drawer"
					class="hidden-lg-and-up align-self-end"
				>
				</v-app-bar-nav-icon>
				<nav class="align-self-end hidden-md-and-down">
					<v-btn
						v-for="link in links"
						:key="link.path"
						:to="link.path"
						text
						small
					>
						{{ link.text }}
					</v-btn>
				</nav>
				<template v-if="$vuetify.breakpoint.mdAndUp">
					<v-spacer></v-spacer>
					<div class="align-self-end">
						<v-btn
							v-for="social in sociales"
							:key="social.icon"
							:href="social.url"
							icon
						>
							<v-icon>{{ social.icon }}</v-icon>
						</v-btn>
					</div>
				</template>
			</v-container>
		</v-app-bar>

		<v-main>
			<router-view class="main-container" />
		</v-main>

		<v-footer
			:app="$vuetify.breakpoint.smAndDown"
			:padless="$vuetify.breakpoint.smAndDown"
			class="grey lighten-2 text-body-2 grey--text text--darken-2 pt-0 px-0"
		>
			<div class="flex-grow-1 footer">
				<div class="main-container">
					<template v-if="$vuetify.breakpoint.smAndDown">
						<div class="d-flex justify-space-between mb-2">
							<v-btn
								text
								class="grey--text text--darken-2 pl-2 pr-1"
								v-if="$vuetify.breakpoint.smAndDown"
								@click="footer = !footer"
							>
								NUESTRAS SEDES
								<v-icon
									right
									dark
								>
									{{ footer ? "mdi-chevron-down" : "mdi-chevron-up" }}
								</v-icon>
							</v-btn>
							<div class="text-right mr-2">
								<v-btn
									v-for="social in sociales"
									:key="social.icon"
									:href="social.url"
									icon
									color="grey darken-2"
								>
									<v-icon>{{ social.icon }}</v-icon>
								</v-btn>
							</div>
						</div>
					</template>
					<template v-else>
						<div class="text-center mt-md-2">
							<v-btn
								v-for="social in sociales"
								:key="social.icon"
								:href="social.url"
								icon
								color="grey darken-2"
								class="mx-4"
							>
								<v-icon>{{ social.icon }}</v-icon>
							</v-btn>
						</div>
					</template>
					<v-expand-transition>
						<div v-if="footer">
							<div
								class="text-center mt-5"
								v-if="$vuetify.breakpoint.mdAndUp"
							>
								Nuestras sedes:
							</div>
							<v-row
								class="mt-1 mb-3"
								justify="center"
							>
								<v-col
									v-for="sede in sedes"
									:key="sede.logo"
									class="d-flex align-center"
									:class="{ 'justify-center': $vuetify.breakpoint.mdAndUp, 'flex-column': $vuetify.breakpoint.sm }"
								>
									<div class="flex-grow-1 flex-shrink-1 text-center">
										<a
											:href="sede.url"
											:title="sede.nombre"
											target="_blank"
											rel="noopener noreferrer"
											class="text-decoration-none"
										>
											<img
												contain
												:src="require('@/assets/img/' + sede.logo + '_bw.png')"
												:alt="sede.nombre"
												width="150"
											/>
										</a>
									</div>
									<div class="flex-grow-1 flex-shrink-1">
										{{ sede.direccion }},
										<br />
										<template v-if="sede.ubicacion">
											{{ sede.ubicacion }}
											<br />
										</template>
										{{ sede.colonia }}
										<br />
										{{ sede.cp }},<template v-if="sede.ciudad">
											{{ sede.ciudad }},
										</template> {{ sede.entidad }}
										<br />
										<a
											:href="sede.urlMapa"
											:title="sede.nombre"
											target="_blank"
											rel="noopener noreferrer"
											class="text-decoration-none"
										>Abrir en Mapas<v-icon
												right
												small
												color="primary"
											> mdi-open-in-new </v-icon></a>
									</div>
								</v-col>
							</v-row>
						</div>
					</v-expand-transition>
					<div class="text-center">
						<router-link
							to="aviso-de-privacidad"
							class="text-decoration-none"
						>
							Aviso de Privacidad
						</router-link>
					</div>
					<div class="text-center mt-2">
						&copy;{{ new Date().getFullYear() }} - Peña Madridista de la Ciudad de México
					</div>
				</div>
			</div>
		</v-footer>

		<v-navigation-drawer
			app
			v-model="drawer"
			color="primary"
			dark
			temporary
			right
		>
			<v-row
				justify="end"
				class="ma-4"
			>
				<v-icon @click.stop="drawer = !drawer">mdi-close-thick</v-icon>
			</v-row>
			<v-divider></v-divider>
			<v-list
				nav
				:dense="height < 650"
			>
				<v-list-item-group
					v-model="selectedItem"
					dark
					color="primary lighten-5"
				>
					<v-list-item
						v-for="(link, i) in links"
						:key="i"
						:to="link.path"
					>
						<v-list-item-icon>
							<v-icon v-text="link.icon"></v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title v-text="link.text"></v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list-item-group>
			</v-list>
		</v-navigation-drawer>
	</v-app>
</template>

<script>
import { datosSociales, datosSedes, datosMembresias } from './assets/data/datosPena';
import { datosLigas, datosEquipos } from './assets/data/datosLigas';
import { datosPlantilla } from './assets/data/datosPlantilla';
import { datosMedios } from '@/assets/data/datosMedios';
import { analytics } from '@/firebaseConfig';
import { logEvent } from 'firebase/analytics';

export default {
	name: 'App',

	metaInfo: {
		changed(metaInfo) {
			logEvent(analytics, 'page_view');
			logEvent(analytics, 'screen_view', {
				firebase_screen: metaInfo.title,
			});
		}
	},

	data: () => ({
		drawer: false,
		footer: false,
		selectedItem: 1,
		height: 0,
		links: [
			{
				text: 'Inicio',
				path: '/',
				icon: 'mdi-home',
			},
			{
				text: '¡Hazte Peñista!',
				path: '/registro',
				icon: 'mdi-card-account-details-star',
			},
			{
				text: 'La Peña',
				path: '/lapena',
				icon: 'mdi-account-group',
			},
			{
				text: 'Eventos',
				path: '/eventos',
				icon: 'mdi-calendar-month',
			},
			{
				text: 'Galería',
				path: '/galeria',
				icon: 'mdi-image-multiple',
			},
			{
				text: 'FAQ',
				path: '/faq',
				icon: 'mdi-help-circle',
			},
			{
				text: 'Contacto',
				path: '/contacto',
				icon: 'mdi-email',
			},
		],
	}),

	computed: {
		sociales() {
			return this.$store.getters.getSociales;
		},
		sedes() {
			return this.$store.getters.getSedes;
		}
	},

	beforeMount() {
		this.height = window.innerHeight;
	},

	mounted() {
		this.footer = this.$vuetify.breakpoint.smAndDown ? false : true;
	},

	beforeCreate() {
		this.$store.dispatch('setSociales', datosSociales);
		this.$store.dispatch('setSedes', datosSedes);
		this.$store.dispatch('setMembresias', datosMembresias);
		this.$store.dispatch('setLigas', datosLigas);
		this.$store.dispatch('setEquipos', datosEquipos);
		this.$store.dispatch('setCalendario');
		this.$store.dispatch('setPlantilla', datosPlantilla);
		this.$store.dispatch('setAnalisisPrev');
		this.$store.dispatch('setAnalisisPost');
		this.$store.dispatch('setNoticias');
		this.$store.dispatch('setEventos');
		this.$store.dispatch('setMedios', datosMedios);
		this.$store.dispatch('setGaleria');
	}
};
</script>

<style lang="scss">
	@import './scss/variables';

	.main-container {
		margin-left: auto;
		margin-right: auto;
		max-width: 1600px;
	}

	.footer {
		border-top: 1px solid #bdbdbd;
	}

	.tituloSeccion {
		border-bottom: 1px solid $primary-lighten5;
		padding-bottom: 4px;
	}

	h2 {
		margin-bottom: 16px;
	}

	// Estilos de listas -----------------------------------------------------------
	ul,
	ol {
		list-style-position: outside;
		margin-bottom: 1rem;

		li::marker {
			color: $secondary-lighten1;
			font-weight: 500;
		}
	}

	ul li::marker {
		font-size: 1.5em;
	}

	ol li::marker {
		font-size: 0.875rem;
	}

	li {
		padding-left: 0.4rem;
	}

	ul.noDecoration {
		list-style: none;
	}

	ol.dec {
		list-style-type: decimal;
	}

	ol.abc {
		list-style-type: lower-latin;
	}

	ol.ABC {
		list-style-type: upper-latin;
	}

	ol.roman {
		list-style-type: lower-roman;
	}

	ol.ROMAN {
		list-style-type: upper-roman;
	}
</style>
