export const datosSociales = [
	{
		nombre: 'Facebook',
		handle: 'madridistascdmx',
		url: 'https://www.facebook.com/madridistascdmxoficial',
		icon: 'fa-brands fa-facebook',
		title: 'fb.me/madridistascdmx'
	},
	{
		nombre: 'Instagram',
		handle: 'madridistascdmx',
		url: 'https://www.instagram.com/madridistascdmx',
		icon: 'fa-brands fa-instagram',
		title: 'madridistascdmx'
	},
	{
		nombre: 'Twitter',
		handle: 'madridistascdmx',
		url: 'https://www.twitter.com/madridistascdmx',
		icon: 'fa-brands fa-x-twitter',
		title: '@madridistascdmx'
	},
	{
		nombre: 'TikTok',
		handle: 'madridistascdmx',
		url: 'https://www.tiktok.com/madridistascdmx',
		icon: 'fa-brands fa-tiktok',
		title: '@madridistascdmx'
	},
	{
		nombre: 'Twitch',
		handle: 'madridistascdmx',
		url: 'https://www.twitch.tv/madridistascdmx',
		icon: 'fa-brands fa-twitch',
		title: '@madridistascdmx'
	},
];

export const datosSedes = [
	{
		nombre: 'UNO by Real Madrid',
		ubicacion: '',
		direccion: 'Mazaryk 29',
		colonia: 'Polanco',
		ciudad: '',
		entidad: 'CDMX',
		cp: '11560',
		logo: 'uno',
		urlMapa: 'https://maps.app.goo.gl/UsLVEZV3GVDEVxsC9',
		mapa: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3762.5578527356406!2d-99.1844638036456!3d19.431500787884758!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1f97f9f7782ad%3A0xebc0f538307a6397!2sUNO%20by%20Real%20Madrid!5e0!3m2!1sen!2smx!4v1699718426559!5m2!1sen!2smx',
		url: 'https://unobyrealmadrid.com/',
		descuento: '5% de descuento',
		descuentoCondiciones: 'en restaurante y Tienda Oficial del Real Madrid (Sólo válido para la tienda del Real Madrid situada dentro del Restaurante UNO By Real Madrid en la Ciudad de México.)',
		aceptaTramites: true,
		estaActiva: true
	},
	{
		nombre: 'Restaurante Puerto Santo',
		ubicacion: 'Complejo Puerta del MAR',
		direccion: 'López Portillo s/n, Mz 84',
		colonia: 'Puerto Juárez',
		ciudad: 'Cancún',
		entidad: 'Q. Roo',
		cp: '77505',
		logo: 'puerto_santo',
		urlMapa: 'https://g.page/puertosantocancun?share',
		mapa: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14881.382129193098!2d-86.80746110577394!3d21.178428416281832!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f4c2ebc2bdf2d07%3A0x5c86f0fb26477fd2!2sPuerto%20Santo!5e0!3m2!1ses-419!2smx!4v1659652827569!5m2!1ses-419!2smx',
		url: 'https://puertosanto.mx/',
		descuento: '10% de descuento',
		descuentoCondiciones: 'en el consumo más 2x1 en bebidas durante partidos que dispute el Real Madrid C.F.',
		aceptaTramites: false,
		estaActiva: true
	}
];

export const datosMembresias = {
	membresias: [
		{
			tipo: 'Básica',
			inscripcion: 350,
			renovacion: 300,
			regalo: 'NO incluye regalo',
			extras: [],
			notas: '',
			icono: 'mdi-account-outline'
		},
		{
			tipo: 'Premium',
			inscripcion: 800,
			renovacion: 700,
			regalo: 'Cupón de $400.00 en la tienda UNO By Real Madrid (Válido hasta el 15 de junio de 2025)',
			extras: [
				'15% de descuento en productos de la Peña',
				'Un acompañante a eventos organizados por la Peña',
				'Prioridad en asignación de entradas e invitaciones *'
			],
			notas: '(*) Respecto a otros Peñistas de la misma categoría conforme al Reglamento Interno y al artículo 27 de los Estatutos vigentes.',
			icono: 'mdi-account-star'
		},
		{
			tipo: 'Infantil',
			inscripcion: 200,
			renovacion: 100,
			regalo: 'Calcomanía, cuaderno o similar.',
			extras: [],
			notas: 'Entre 12 y 18 años.',
			icono: 'mdi-human-male-child'
		}
	],
	vigencia: '20 de mayo de 2025',
	isPeriodoRenovacion: false,
};

export const datosPena = {
	juntaDirectiva: [
		{
			nombre: 'Alejandro Aybar Arribas',
			cargo: 'Presidente'
		},
		{
			nombre: 'Carlos Salcido de Pablo',
			cargo: 'VicePresidente'
		},
		{
			nombre: 'José Manuel Velázquez Rangel',
			cargo: 'Secretario'
		}]
};
